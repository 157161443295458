.workoutsContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.workoutsContent{
    display: flex;
}

.workoutsList{
    width: 100%;
    height: calc(100vh - 120px);
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(300px,350px));
    grid-template-rows: repeat(auto-fit,300px); */
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(auto-fill,300px);
    justify-content: space-evenly;
    gap: 10px;
    /* padding: 10px; */
    margin: 10px;
    overflow: auto;
}

.workoutsLinks{
    text-decoration: none;
    color: black;
}

