.rspContainer{
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    place-items: center;
    margin: 10px;
    background-color: var(--primary-1);
    overflow-y: scroll;
    overflow-x: hidden;

}

.rspContainer > h1{
    font-family: Roboto;
    font-style: normal;
    font-size: 32px;
    line-height: 150%; /* 60px */
    margin: 30px 2px;
    letter-spacing: 0.04em;
    word-spacing: 0.15em;
    font-weight: 600;
    text-align: center;
}

.rspContainer > h2{
  width: min(97%, 800px);
  font-family: Roboto;
  font-style: normal;
  font-size: 24px;
  line-height: 150%; /* 60px */
  margin: 30px 2px 10px 2px;
  letter-spacing: 0.04em;
  word-spacing: 0.15em;
  font-weight: 600;
}

.rspContainer > p{
    width: min(97%, 800px);

    font-family: Roboto;
    font-style: normal;
    color: var(--primary-5);
    font-size: 17px;
    line-height: 150%;
    word-spacing: 0.5em;
    letter-spacing: 0.05em;
    font-weight: 400;
    margin-bottom: 15px;
}

.rspCalculatorForm{
  width: min(85%, 800px);
  display: grid;
  /* align-items: flex-start; */
  place-items: center;
  text-align: center;
  
  gap: 15px;
  border: 2px solid var(--primary-3);
  padding: 10px;
  margin: 2px;
  border-radius: 10px;

  font-family: Roboto;
  font-style: normal;
  color: var(--primary-5);
  font-size: 17px;
  line-height: 150%;
  word-spacing: 0.5em;
  letter-spacing: 0.05em;
  font-weight: 400;
  margin-bottom: 36px;
}

.rspCalculatorRoutine{
    display: grid;
    width: min(99%, 850px);
    grid-template-columns: repeat(auto-fill, minmax(320px,1fr));
    gap: 10px;
    place-items: center;
    margin-bottom: 30px;
}

.rspCalculatorRoutineTable{
    width: min(100%,320px);
    display: grid;
    margin: 25px 0 10px 0;
    color: var(--primary-5);
    font-family: Plus Jakarta Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: 0.5px;
    word-spacing: 1px;
    gap: 1px;
}

.rspCalculatorRoutineTable > h2{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    font-weight: 600;
    letter-spacing: 0.05em;
    word-spacing: 0.25em;
    text-align: center;
}

.rspCalculatorRoutineTableRow{
    display: grid;
    width: 100%;
    grid-template-columns: 90px 1fr;
    gap: 1px;
}
.rspCalculatorRoutineTableRow > span{
    padding: 5px 20px;
    background-color: var(--primary-2);
}







.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
    margin-left: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--secondary-2);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }