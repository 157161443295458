

.loginContainer{
    height: 100vh;
    width: 100vw;
    display: grid;
    justify-items: center;
    align-content: center;
    background-color: var(--primary-2);
}

.loginHeading{
    place-self: center;
    color: var(--secondary-3);
    font-family: Plus Jakarta Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    letter-spacing: -1.2px;
    margin: 10px;
}

.loginForm{
    display: grid;
    width: max(30vw,250px);
    padding: 20px;
    grid-gap: 20px;
    gap: 20px;
    background-color: var(--primary-1);
    border-radius: 10px;
}

.loginSubmit{
    width: 100%;
    height: 45px;
    background-color: var(--secondary-2);
    border-radius: 10px;
    border: none;
    color: var(--primary-1);

    /* Semibold/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}
.registerContainer{
    height: 100vh;
    width: 100vw;
    display: grid;
    justify-items: center;
    align-content: center;
    background-color: var(--primary-2);
}

.registerHeading{
    place-self: center;
    color: var(--secondary-3);
    font-family: Plus Jakarta Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    letter-spacing: -1.2px;
    margin: 10px;
}

.registerForm{
    display: grid;
    width: max(30vw,250px);
    padding: 20px;
    grid-gap: 20px;
    gap: 20px;
    background-color: var(--primary-1);
    border-radius: 10px;
}


.formInputContainer{
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    color: var(--secondary-2);
    /* Semibold/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}

.formInputField{
    width: 90%;
    height: 45px;
    border-radius: 10px;
    outline: none;
    background-color: var(--primary-2);
    border: none;
    padding: 0 5%;
}

.registerSubmit{
    width: 100%;
    height: 45px;
    background-color: var(--secondary-2);
    border-radius: 10px;
    border: none;
    color: var(--primary-1);

    /* Semibold/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}

.error{
    color: red;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}

.formLink{
    text-align: center;
}
::-webkit-scrollbar {
    width: 3px;
}
  
::-webkit-scrollbar-track {
  background-color: #F1F0F9;
}

::-webkit-scrollbar-thumb {
  background-color: #E2E6EB;
}

:root{
  --primary-1: #FFFFFF;
  --primary-2: #F6F7F9;
  --primary-3: #90A3BF;
  --primary-4: #596780;
  --primary-5: #1A202C;
  --secondary-0: #AEC8FC;
  --secondary-1: #85A8F8;
  --secondary-2: #3563E9;
  --secondary-3: #1A37A7;
  --secondary-4: #0A196F;
  
}

.text{
  font-family: Plus Jakarta Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 60px */
  letter-spacing: -1.2px;
}
.text.sz_12{
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0px;
}
.text.sz_14{
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
}
.text.sz_16{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
}
.text.sz_18{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
}
.text.sz_20{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.text.sz_24{
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.text.sz_32{
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -1px;
}
.text.wt_3{
  font-weight: 300;
}
.text.wt_4{
  font-weight: 400;
}
.text.wt_5{
  font-weight: 500;
}
.text.wt_6{
  font-weight: 600;
}
.text.clr_w{
  color: white;
}
.text.clr_r{
  color: #DB2719;
}
.text.mr_5{
  margin: 5px 0px;
}
.text.aln_c{
  text-align: center;
}

.homeContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.header{
  width: 100vw;
}

.homeContent{
  display: flex;
  /* overflow-y: scroll; */
}

.homeContentGyms{
  width: 100%;
  /* height: 100vh; */
}
.sideBarContainer{
    width: 300px;
    height: 100%;
    background: var(--primary-1);
    transition: all 0.3s;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--primary-2);
    padding: 16px;
}


.sideBarContainer.close{
    width: 45px;
}

.sideBarToggleArrow{
    border: 1px solid var(--primary-4);
    border-radius: 50px;
    height: 20px;
    width: 20px;
    float: right;
    margin-bottom: 10px;
    fill: var(--primary-4);
}
.sideBarToggleArrow:hover{
    cursor: pointer;
    border-color: var(--secondary-2);
    background-color: var(--secondary-2);
    fill: var(--primary-1);
}
.sideBarToggleArrow.right{
    margin: 0 10px 20px 0;
}

.sideBarHeading{
    color: rgba(148, 167, 203, 0.40);
    text-align: justify;

    /* Semibold/Type@12 */
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.24px;
    margin: 20px 0 20px 10px;
}

.sideBarButton{
    display: flex;
    width: 100%;
    height: 50px;
    margin: 5px 0;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}
.sideBarButton:hover{
    background: var(--secondary-2);
    cursor: pointer;
}
.sideBarButtonIcon{
    margin: 10px;
    height: 20px;
    width: 20px;
    display: grid;
    place-items: center;
    fill: var(--primary-4);
}
.sideBarButton:hover > .sideBarButtonIcon{  
    fill: white;
}
.sideBarButtonText{
    color: var(--primary-4);
    text-align: justify;

    /* Medium/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
    margin: 10px;
}
.sideBarButton:hover > .sideBarButtonText{
    color: white;
}
.sideBarButtonText.close{
    display: none;
}

.sideBarTheme{
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}
.themeCircle{
    height: 12px;
    width: 12px;
    border-radius: 10px;
    border: 1px solid var(--secondary-2);
    margin-left: 10px;
    background: content-box;
    padding: 1px;
}
.themeCircle:hover{
    cursor: pointer;
}

.overlay{
    background-color: black;
    opacity: 0.7;
    z-index: 1;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: none;
}

.linkTo{
    text-decoration: none;
}

.sideBarUser{
    display: none;
}

.sideBarAuth{
    display: none;
}

.sideBarClose{
    display: none;
}


@media screen and (max-width: 800px){
    .sideBarContainer{
        position: absolute;
        z-index: 2;
        top: 0%;
        width: 250px;
        height: calc(100vh - 32px);
        overflow-y: scroll;
        border: none;
    }
    .sideBarContainer.close{
        width: 0px;
        padding: 0;
    }
    .overlay{
        display: block;
        height: 100vh;
    }

    .sideBarToggle{
        display: none;
    }

    .sideBarClose{
        display: block;
        height: 20px;
        width: 20px;
        text-align: center;
        position: absolute;
        left: 250px;
        top: 10px;
        z-index: 1;
        font-weight: 700;
        background-color: var(--primary-3);
        border-radius: 10px;
    }

    .sideBarUser{
        display: flex;
        place-items: center;
    }
    .sideBarUser > span{
        color: var(--primary-5);
        text-align: justify;

        /* Medium/Type@16 */
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.32px;
        margin: 10px;
    }
    .sideBarUserIcon{
        height: 28px;
        width: 28px;
        background-color: var(--secondary-2);
        border-radius: 30px;
        fill: var(--primary-1);
        padding: 10px;
        margin: 10px;
    }
    .sideBarSettings{
        display: grid;
    }

    .sideBarAuth{
        display: block;
        margin-top: 20px;
    }
    .sideBarAuthButton{
        border-radius: 10px;
        margin-top: 4px;
        padding: 5px;
        display: grid;
        grid-gap: 5px;
        gap: 5px;
        place-items: center;
    }
    .sideBarAuthButtonValue{
        height: 30px;
        width: 90%;
        border-radius: 10px;
        padding-top: 8px;
        text-align: center;
        text-decoration: none;
        border: 1px solid var(--primary-3);
    }
    .sideBarAuthButtonValue:hover{
        background-color: var(--secondary-2);
        color: white;
        cursor: pointer;
    }

}

.dark{
    color: white;
    fill: white;
}
.darkBack{
    background-color: #18171A;
}
.dark > span{
    color: white;
}
.headerContainer{
    display: grid;
    grid-template-columns: 300px 1fr 1fr;
    height: 70px;
    background: var(--primary-1);
    transition: 200ms ease-in-out;
}

.headerLogo{
    place-self: center;
    color: var(--secondary-2);
    letter-spacing: -1.2px;

    display: flex;
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    place-items: center;
}
.logoH{
    height: 50px;
    width: 50px;
    fill: var(--secondary-2);
    border-radius: 100px;
    border: 2px solid var(--secondary-2);
    background-color: var(--primary-1);
}
.logoH.red{
    fill: #DB2719;
    border-radius: 100px;
    border: 2px solid #DB2719;
    background-color: var(--primary-5);
}
.logoHText{
    height: 70px;
    display: grid;
    grid-template-rows: 1fr 12px;
    align-items: flex-end;
}
.logoHSlang{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;   
    font-size: 16px;
    margin-left: 20px;
}

.headerSearchBar{
    position: relative;
    place-self: center;
    display: grid;
    grid-template-columns: 1fr 40px;
    width: 95%;
    height: 40px;
    border-radius: 70px;
    border: 1px solid rgba(195, 212, 233, 0.40);
    background-color: var(--primary-2);
    padding: 0 10px 0 10px;
    
}
.headerSearchBar:focus-within{
    border: 2px solid orange;
}
.headerSearchIcon{
    align-self: center;
    justify-self: center;
    /* margin-left: 13px; */
    color: var(--primary-2);
    height: 20px;
    width: 20px;
    opacity: 0.6;
}
.headerSearchInputBox{
    width: 95%;
    border: none;
    text-align: justify;

    /* Medium/Type@14 */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.5px;
    word-spacing: 1.5px;
    background-color: var(--primary-2);
    place-self: center;
}
.headerSearchInputBox:focus{
    border: none;
    outline: none;
}
.headerSearchResults{
    position: absolute;
    top: 50px;
    left: 0px;
    width: 95%;
    z-index: 3;
    background-color: var(--primary-1);
    box-shadow: 0px 0px 5px 0px var(--primary-4);
    border-radius: 10px;
    padding: 10px;

    text-align: left;
    vertical-align: middle;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.5px;
    word-spacing: 1.5px;

    display: grid;
}
.headerSearchResultLinks{
    text-decoration: none;
    color: var(--primary-5);
    width: 95%;
    height: 40px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 2px solid var(--primary-2);
    place-self: center;
    cursor: pointer;
    padding: 0 10px;
}
.headerSearchResultLinks:hover{
    background-color: var(--primary-2);
}
.headerSearchResultLinks:last-child{
    border: none;
}

.headerMenu{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;
    gap: 20px;
    margin-right: 20px;
}
.headerMenuButtons{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    border: 1px solid rgba(195, 212, 233, 0.40);
}
.headerMenuButtons:hover{
    background: var(--secondary-2);
    cursor: pointer;
}
.headerMenuIcon{
    height: 20px;
    width: 20px;
}
.headerMenuButtons:hover > .headerMenuIcon{
    fill: white;
}

.searchIcon{
    display: none;
}

.headerFilterBar{
    height: 45px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
}
.headerFilterBar::-webkit-scrollbar{
    display: none;
}

.filterBtn{
    width: 80%;
    height: 27px;
    background: #E2E6EB;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px;
}

.headerContainerSS{
    display: none;
}

.userDropDown{
    position: absolute;
    top: 70px;
    right: 35px;
    width: 200px;
    z-index: 3;
    background-color: var(--primary-1);
    box-shadow: 0px 0px 5px 0px var(--primary-4);
    border-radius: 10px;
    padding: 10px;

    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.userInfo{
    font-size: 20px;
    margin-bottom: 20px;
}
.dropDownItems{
    border-radius: 10px;
    margin-top: 4px;
    padding: 5px;
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    place-items: center;
}
.dropDownItemOption{
    height: 30px;
    width: 90%;
    border-radius: 10px;
    padding-top: 8px;
    text-align: center;
    text-decoration: none;
    border: 1px solid var(--primary-2);
}
.dropDownItemOption:hover{
    background-color: var(--secondary-2);
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .headerContainer{
        grid-template-columns: 250px 1.5fr 1fr;
    }
    .headerLogo{
        font-size: 36px;
    }
    
}

@media screen and (max-width: 800px) {
    .headerContainer{
        display: none;
    }
    .headerContainerSS{
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        height: 70px;
        background-color: var(--primary-1);
    }
    .headerIcon{
        align-self: center;
        justify-self: center;
        fill: var(--primary-5);
    }
    .headerIcon:hover{
        background-color: var(--primary-2);
        padding: 10px;
        border-radius: 50px;
    }
    .headerLogo{
        
    }
    .headerLogo.hide{
        display: none;
    }

    .searchIcon{
        display: block;
        position: absolute;
        z-index: 0;
        right: 10px;
        top: 10px;
    }
    

    .headerToggleSideBar{
        top: 5px;
      }
}
.blogsContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.blogsContent{
    display: flex;
}

.blogsFiltersList{
    display: grid;
    width: calc(100% - 30px);
    padding: 10px;
}

.blogsFiltersScroll{
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 0 20px 0 20px; */
    /* justify-content: center; */
    overflow: hidden;
}
.blogsFilters{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 20px;
    overflow: scroll;
}
.blogsFilters::-webkit-scrollbar{
    height: 0;
    width: 0;
}
.blogsFilterButton{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--primary-5);
    border-radius: 5px;
    /* margin: 10px; */
    padding: 2px 10px;
}
.blogsFilterButton:hover{
    cursor: pointer;
    background-color: var(--secondary-2);
    color: white;
    border: none;
}
.blogsFilterButton.selected{
    background-color: var(--secondary-2);
    color: white;
    border: none;
}

.blogsList{
    width: 100%;
    height: calc(100vh - 120px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(315px,1fr));
    grid-template-rows: repeat(auto-fit,390px);
    grid-gap: 10px;
    gap: 10px;
    /* padding: 10px; */
    margin: 10px;
    overflow: auto;
}
.blogsList::-webkit-scrollbar{
    height: 0;
}

.blogsLinks{
    text-decoration: none;
    color: black;
}
.blogsCardContainer{
    display: grid;
    grid-template-rows: 1fr 1.5fr;
    height: 380px;
    width: 97%;
    cursor: pointer;
    background: var(--primary-1);
    border-radius: 10px;
    overflow: hidden;
}
.blogsCardContainer:hover{
    box-shadow: 0px 0px 5px 0px var(--primary-4);
}

.blogsCardImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.blogsCardDetails{
    padding: 10px;
    display: grid;
    /* align-items: center; */
    grid-gap: 4px;
    gap: 4px;
}

.blogsCardDetails > h1{
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    word-spacing: 2px;
}
.blogsCardDetails > h2{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-4);
    /* letter-spacing: 0.25px; */
    word-spacing: 1px;
}
.blogsCardDetails > h3{
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-3);
}

.blogsCardTag{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 10px;
    text-align: center;
    text-justify: center;
    border-radius: 15px;
    height: 18px;
}
.blogsCardTag.red{
    background: linear-gradient(0deg, #FFA37A 0%, #DB2719 30%);
}
.blogsCardTag.blue{
    background: linear-gradient(0deg, #98D3FF 0%, #2A60B7 30%);
}
.blogsCardTag.yellow{
    background: linear-gradient(0deg,#FFE488 0%,#DBA32A 30%);
}
.blogsCardTag.green{
    background: linear-gradient(0deg, #D3F178 0%, #7FB519 30%);
}

.blogsHeader{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    place-items: center;
    height: 90px;
    /* background: linear-gradient(0deg, var(--primary-2) 0%, var(--primary-1) 50%); */
    background-color: var(--primary-5);
    width: 100vw;
    
}

.blogsHeaderLogo{
    display: flex;
    color: var(--primary-1);
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    place-items: center;
}
.logo{
    height: 70px;
    width: 70px;
    fill: var(--primary-5);
    border-radius: 100px;
    border: 2px solid var(--primary-5);
    background-color: var(--primary-1);
    /* paint-order: stroke;
    stroke: var(--primary-1);
    stroke-width: 15px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    font-weight: 800; */
}
.logoText{
    height: 90px;
    display: grid;
    grid-template-rows: 1fr 18px;
    align-items: flex-end;
}
.logoSlang{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;   
    font-size: 18px;
    margin-left: 20px;
}

.blogsHeaderOptions{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;

    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 1.5px;
    grid-gap: 10px;
    gap: 10px;
}

.blogsHeaderRoutes{
    width: 100px;
    padding: 4px;
    color: var(--primary-1);
}
.blogsHeaderRoutes:hover{
    /* background-color: var(--primary-3); */
    border-bottom: 3px solid var(--primary-1);
}
.blogsHeader button{
    border: none;
    border: 1px solid var(--primary-1);
    background: transparent;
    color: var(--primary-1); 
    width: 100px;
    /* border-radius: 4px; */
    margin: 8px;
    padding: 4px;
    /* transform: skewX(-15deg); */
    font-size: 18px;
    letter-spacing: 1px;
}
.blogsHeader button:hover{
    background-color: var(--primary-1);
    color: black;
    cursor: pointer;
}

.blogsHeaderSS{
    display: none;
}

.blogsSideBar::-webkit-scrollbar{
    width: 0;
}

@media screen and (max-width: 1000px) {
    .blogsHeaderRoutes{
        width: 80px;
        font-size: 16px;
    }
    .blogsHeader button{
        width: 80px;
        font-size: 16px;
    }
    .blogsHeaderLogo{
        font-size: 36px;
    }
    .logo{
        height: 60px;
        width: 60px;
    }
}

@media screen and (max-width: 800px){
    .blogsHeader{
        display: none;
    }
    .blogsHeaderLogo{
        color: var(--primary-1);
    }
    .logo{
        height: 50px;
        width: 50px;
    }
    .logoText{
        height: 70px;
        display: grid;
        grid-template-rows: 1fr 14px;
        align-items: flex-end;
    }
    .logoSlang{
        font-size: 16px;
    }
    .blogsHeaderSS{
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        height: 70px;
        width: 100vw;
        background-color: var(--primary-5);
        place-items: center;
    }
    .blogsHeaderIcon{
        align-self: center;
        justify-self: center;
        fill: var(--primary-1);
    }
}
.blogContainer{
    display: grid;
    place-items: center;
    background-color: var(--primary-1);
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.blogParas{
    background-color: var(--primary-1);
    width: min(90%,800px);
    padding: 50px 20px 20px 20px;
}

.blogsImgContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
}
.blogsImg{
    width: 100%;
    height: min(400px,60vw);
    object-fit: cover;
    overflow: hidden;
}
.blogsImg.small{
    width: 90%;
    height: min(250px, 50vw);
}

.blogsAuthor{
    display: flex;
    align-items: center;
    margin-top: 20px;
    grid-gap: 20px;
    gap: 20px;
}
hr{
    border-top: 2px solid var(--primary-5);
}
.blogsAuthorText{
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.blogsAuthorText.light{
    opacity: 0.7;
}
.blogsAuthorImg{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    object-fit: cover;
    overflow: hidden;
}

.blogParas > h1{
    font-family: Roboto;
    font-style: normal;
    font-size: 32px;
    line-height: 150%; /* 60px */
    margin-bottom: 50px;
    letter-spacing: 0.04em;
    word-spacing: 0.15em;
    font-weight: 600;
}
.blogParas > h2{
    font-family: Roboto;
    font-style: normal;
    font-size: 25px;
    line-height: 150%;
    margin: 50px 0 16px 0;
    font-weight: 600;
    letter-spacing: 0.05em;
    word-spacing: 0.25em;
}
.blogParas > h3{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
    word-spacing: 0.25em;
}
.blogParas > p{
    font-family: Roboto;
    font-style: normal;
    color: var(--primary-5);
    font-size: 17px;
    line-height: 150%;
    word-spacing: 0.5em;
    letter-spacing: 0.05em;
    font-weight: 400;
    margin-bottom: 36px;
}
.blogParas > ol,li,ul{
    margin: 12px 0;
}

.blogsQuotes{
    text-align: center;
    font-style: italic;
    font-weight: bold;
    border-width:  3px;
    border-style: double;
    padding: 10px 30px;
}

.blogsSideBar{
    display: none;
    position: absolute;
    left: 0%;
    top: 0%;
}
.blogsSideBarOverlay{
    position: absolute;
    display: none;
    width: 100%;
    height: 100vh;
    top: 0%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
}

@media screen and (max-width: 800px){
    .blogsSideBar{
        display: block;
    }
    .blogsSideBarOverlay{
        display: block;
    }
}

.blogsFooterContainer{
    width: 100%;
    background-color: var(--primary-5);
    color: white;
    display: grid;
    place-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.blogsFooterUpper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 200px;
    grid-gap: min(100px, 10vw);
    gap: min(100px, 10vw);
    font-family: Plus Jakarta Sans;
    padding: 50px 10px 0 10px;
}

.blogsFooterContactExplore{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    font-size: 17px;
    justify-self: center;
}

.blogsFooterLinks{
    text-decoration: none;
    color: var(--primary-3);
}
.blogsFooterLinks:hover{
    text-decoration: underline;
}

.blogsFooterLower{
    background-color: var(--primary-4);
    width: 100%;
    height: 50px;
    display: flex;
    place-items: center;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 500px){
    .blogsFooterContactExplore{
        font-size: 14px;
    }
}

.bmiCalcContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.bmiCalcContent{
    display: flex;
}

.bmiCalcFormContainer{
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    place-items: center;
    margin: 20px;
    background-color: var(--primary-1);
    overflow-y: scroll;
    overflow-x: hidden;
}

.bmiCalcHeading{
    text-align: center;
    margin-top: 10px;  

    color: var(--secondary-3);

    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -0.50px;
}

.bmiInfo{
    margin: 10px;
    max-width: 600px;
    text-align: justify;

    color: var(--primary-5);

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
}
.checkBmiForm{
    width: min(50%,400px);
    display: grid;
    grid-gap: 20px;
    padding: 30px;
    border: 3px solid var(--primary-2);
    border-radius: 10px;
    /* margin-bottom: 20px; */
}
.bmiFormInputDiv{
    display: grid;
}
.bmiFormLabel{
    font-size: 20px;
}
.bmiFormButton{
    height: 35px;
    background-color: var(--secondary-2);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 18px;
}
.bmiFormRadioButton{
    float: right;
    font-size: 15px;
    margin: 2px;
}
.bmiValue{
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--secondary-3);
}

input[type ="radio"]{
    margin: 2px;
    accent-color: var(--secondary-2);
}

.bmiChart{
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: min(350px,90%);
    margin: 25px 0 10px 0;
    /* border-top: 2px solid black; */
    /* border-left: 2px solid black; */
    color: var(--primary-1);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    grid-gap: 1px;
    gap: 1px;
}
.bmiChartValues{
    /* border-bottom: 2px solid black; */
    /* border-right: 2px solid black; */
    padding: 5px;
    grid-gap: 1px;
    gap: 1px;
    margin-top: 1px;
}
.bmiRangeValue{
    /* border-bottom: 2px solid black; */
    /* border-right: 2px solid black; */
    text-align: center;
    padding: 5px;
}
.genderSelect{
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 50px;
}
.genderSelectBtn{
    border: 2px solid var(--primary-3);
    width: 100px;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: var(--primary-5);
}
.genderSelectBtn.selected{
    background-color: var(--secondary-2);
    color: white;
    border-color: var(--secondary-3);
}

select {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    outline: none;
    background-color: var(--primary-2);
    border: none;
    padding: 0 3%;
    color: var(--primary-5);
    cursor: pointer;
}
select:focus{
    outline: none;
    border: 2px solid var(--secondary-2);
}

.error{
    background-color: #DB2719;
    border-radius: 3px;
}

.bmrChart{
    display: grid;
    min-width: min(350px,90%);
    margin: 25px 0 10px 0;
    color: var(--primary-5);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    grid-gap: 1px;
    gap: 1px;
}
.bmrChartRow{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1px;
    gap: 1px;
}
.bmrChartRow > span{
    padding: 5px;
}
.bmrChartRow:nth-child(odd) > span{
    background-color: var(--secondary-1);
}
.bmrChartRow:nth-child(even) > span{
    background-color: var(--secondary-0);
}
.bmrChartRow > span:nth-child(even){
    text-align: center;
}

.toolsBack{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 20px 0 0;
    font-size: 16px;
}
.toolsBackIcon{
    height: 18px;
    width: 18px;
    border: 2px solid var(--secondary-2);
    border-radius: 20px;
    fill: var(--secondary-2);
    margin: 0 5px;
}
.calcLink{
    display: flex;
    text-decoration: none;
    color: var(--secondary-3);
}

.toolsContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.toolsContent{
    display: flex;
}

.toolsCalculators{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-template-rows: repeat(auto-fill,200px);
    justify-content: space-evenly;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
}

.toolsCalc{
    height: 200px;
    width: 200px;
    background-color: var(--primary-1);
    border-radius: 10px;
    display: grid;
    place-items: center;

    color: var(--secondary-2);

    /* Bold/Type@32 */
    font-family: Plus Jakarta Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -0.50px;
}
.rspContainer{
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    place-items: center;
    margin: 10px;
    background-color: var(--primary-1);
    overflow-y: scroll;
    overflow-x: hidden;

}

.rspContainer > h1{
    font-family: Roboto;
    font-style: normal;
    font-size: 32px;
    line-height: 150%; /* 60px */
    margin: 30px 2px;
    letter-spacing: 0.04em;
    word-spacing: 0.15em;
    font-weight: 600;
    text-align: center;
}

.rspContainer > h2{
  width: min(97%, 800px);
  font-family: Roboto;
  font-style: normal;
  font-size: 24px;
  line-height: 150%; /* 60px */
  margin: 30px 2px 10px 2px;
  letter-spacing: 0.04em;
  word-spacing: 0.15em;
  font-weight: 600;
}

.rspContainer > p{
    width: min(97%, 800px);

    font-family: Roboto;
    font-style: normal;
    color: var(--primary-5);
    font-size: 17px;
    line-height: 150%;
    word-spacing: 0.5em;
    letter-spacing: 0.05em;
    font-weight: 400;
    margin-bottom: 15px;
}

.rspCalculatorForm{
  width: min(85%, 800px);
  display: grid;
  /* align-items: flex-start; */
  place-items: center;
  text-align: center;
  
  grid-gap: 15px;
  
  gap: 15px;
  border: 2px solid var(--primary-3);
  padding: 10px;
  margin: 2px;
  border-radius: 10px;

  font-family: Roboto;
  font-style: normal;
  color: var(--primary-5);
  font-size: 17px;
  line-height: 150%;
  word-spacing: 0.5em;
  letter-spacing: 0.05em;
  font-weight: 400;
  margin-bottom: 36px;
}

.rspCalculatorRoutine{
    display: grid;
    width: min(99%, 850px);
    grid-template-columns: repeat(auto-fill, minmax(320px,1fr));
    grid-gap: 10px;
    gap: 10px;
    place-items: center;
    margin-bottom: 30px;
}

.rspCalculatorRoutineTable{
    width: min(100%,320px);
    display: grid;
    margin: 25px 0 10px 0;
    color: var(--primary-5);
    font-family: Plus Jakarta Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: 0.5px;
    word-spacing: 1px;
    grid-gap: 1px;
    gap: 1px;
}

.rspCalculatorRoutineTable > h2{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    font-weight: 600;
    letter-spacing: 0.05em;
    word-spacing: 0.25em;
    text-align: center;
}

.rspCalculatorRoutineTableRow{
    display: grid;
    width: 100%;
    grid-template-columns: 90px 1fr;
    grid-gap: 1px;
    gap: 1px;
}
.rspCalculatorRoutineTableRow > span{
    padding: 5px 20px;
    background-color: var(--primary-2);
}







.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
    margin-left: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--secondary-2);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.wrapperContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.wrapperContent{
    display: flex;
}
.textInput{
    position: relative;
}
.textInput input{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border: 2px solid var(--primary-4);
    outline: none;
    background: transparent;

    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background-color: var(--primary-1); */
    padding: 0 5%;

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 1px;
    word-spacing: 2px;
}

.textInput span{
    position: absolute;
    top: 22%;
    left: 15px;
    transition: 0.3s;
    padding: 0 2px;
    background-color: var(--primary-1);
    color: var(--primary-4);

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 1px;
    word-spacing: 2px;
}

.textInput input:focus{
    /* border: 2px solid var(--secondary-3); */
}

.textInput input:focus ~ span,
.textInput input:valid ~ span {
  transform: translateX(-10%) translateY(-100%);
  color: var(--primary-5);
  font-size: 14px;
  z-index: 2;
}
.workoutsContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.workoutsContent{
    display: flex;
}

.workoutsList{
    width: 100%;
    height: calc(100vh - 120px);
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(300px,350px));
    grid-template-rows: repeat(auto-fit,300px); */
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(auto-fill,300px);
    justify-content: space-evenly;
    grid-gap: 10px;
    gap: 10px;
    /* padding: 10px; */
    margin: 10px;
    overflow: auto;
}

.workoutsLinks{
    text-decoration: none;
    color: black;
}


.workoutsCardContainer{
    display: grid;
    grid-template-rows: 1.2fr 1fr;
    height: 100%;
    width: 97%;
    cursor: pointer;
    background: var(--primary-1);
    border-radius: 10px;
    overflow: hidden;
    margin: 5px;
}
.workoutsCardContainer:hover{
    box-shadow: 0px 0px 5px 0px var(--primary-4);
}

.workoutsCardImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.workoutsCardDetails{
    padding: 10px;
    display: grid;
    /* align-items: center; */
    grid-gap: 4px;
    gap: 4px;
}

.workoutsCardDetails > h1{
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    word-spacing: 2px;
}
.workoutsCardDetails > h2{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-4);
    /* letter-spacing: 0.25px; */
    word-spacing: 1px;
}
.workoutsCardDetails > h3{
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-3);
}

.policyContainer{
    display: grid;
    background-color: var(--primary-1);
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    place-items: center;
}

.policyTerms{
    background-color: var(--primary-1);
    width: min(90%,800px);
    padding: 20px;
}
.gymsContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill, 340px);
    justify-content: space-evenly;
    margin-top: 5px;
    grid-gap: 5px;
    gap: 5px;
    height: calc(100vh - 95px);
    overflow-y: scroll;
}

@media screen and (max-width: 400px) {
    .gymsContainer{
        grid-gap: 0;
        gap: 0;
    }
}
.gymCardContainer{
    display: grid;
    grid-template-rows: 1fr 1.4fr;
    height: 430px;
    width: 340px;
    cursor: pointer;
    margin: 5px;
    background: var(--primary-1);
    border-radius: 10px;
    overflow: hidden;
}
.gymCardContainer:hover{
    box-shadow: 0px 0px 5px 0px var(--primary-4);
}

.gymCardImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.gymCardDetails{
    background: var(--primary-1);
    padding: 10px 20px 20px 20px;
}

.gymCardTitle{
    color: var(--secondary-2);

    /* Bold/Type@32 */
    font-family: Plus Jakarta Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -0.50px;
}

.gymCardStar{
    height: 16px;
    width: 16px;
    fill: gold;
    margin: 2px;
}

.gymCardContactInfo{
    margin: 20px 0;
    display: grid;
    grid-gap: 10px;
    gap: 10px
}
.gymInfo{
    display: flex;

    color: var(--primary-4);
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
}
.gymCardInfoIcon{
    height: 18px;
    width: 18px;
    fill: var(--primary-5);
    place-self: center;
    margin-right: 10px;
}
.gymCardContactInfoText{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
}

.gymCardFeeInfo{
    display: grid;
    width: 90%;
}

.gymCardFeeInfoRow{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* justify-content: center; */
    grid-gap: 30px;
    gap: 30px;

    color: var(--primary-4);
    /* Regular/Type@20 */
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
}

.gymCardFeeInfoSpan{
    color: var(--primary-5);
    float: right;
    margin-left: 5px;
}

@media screen and (max-width: 400px) {
    .gymCardContainer{
        transform: scale(0.95);
        margin: 0;
    }
}
.landingContainer{
    /* height: 100vh;
    width: 100vw;
    background-image: linear-gradient(0deg, rgb(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255,255,255,1) 100%), 
                        url("/assets/landing_2.jpg");
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat; */
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #18171A; /*rgba(24, 23, 26, 1)*/
}
.landingContainer::-webkit-scrollbar{
    width: 0px;
}

.landingHeadings{
    /* width: 100%; */
    text-align: center;
    margin: 50px 0 20px 0;

    color: white;

    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.landingHeadings.small{
    font-size: 16px;
    margin: 0 0 20px 0;
}

.landingHeader{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    place-items: center;
    margin: 5px 0;
    height: 80px;
    /* padding: 20px 0; */
    /* background-color: var(--primary-4); */
}
.landingHeaderOptions{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;

    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.landingHeaderRoutes{
    width: 100px;
    padding: 4px;
    color: white;
}
.landingHeaderRoutes:hover{
    /* background-color: var(--primary-3); */
    border-bottom: 3px solid #DB2719;
}
.landingHeader button{
    border: none;
    border: 2px solid var(--primary-5);
    border-right: 3px solid #DB2719;
    border-left: 3px solid #DB2719;
    background: transparent;
    color: white; 
    width: 100px;
    /* border-radius: 4px; */
    margin: 8px;
    padding: 4px;
    /* transform: skewX(-15deg); */
    font-size: 18px;
}
.landingHeader button:hover{
    background-color: #DB2719;
    color: white;
    cursor: pointer;
}

.landingImageSlider{
    height: 600px;
    width: 100vw;
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
}
.slide{
    height: 100%;
    width: 100vw;
    scroll-snap-align: start;
    place-items: center;
    position: relative;
    transform: transale3d(0,0,0); 
    animation: moveSlideshow 20s linear infinite;
}
.landingImageContainer{
    width: 100vw;
    height: 100%;
    position: relative;
}
.landingImageCaption{
    position: absolute;
    bottom: 30px;
    left: 40px;
    z-index: 2;
    color: white;
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.landingImageCaption span{
    color: transparent;
    -webkit-text-stroke-width: 2.5px;
    -webkit-text-stroke-color: red;
    letter-spacing: 3px;
    font-size: 48px;
}
.landingImageSlider img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.landingImageOverlay_h{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 75%);
    /* background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%); */
    height: 100%;
    width: 100%;
}
.landingImageSlider::-webkit-scrollbar{
    height: 0px;
}

.landingFindWhatMovesYou{
    width: 100vw;
}
.landingWorkoutTypes{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}
.landingWorkoutImage{
    position: relative;
}
.landingWorkoutImage img{
    width: 487px;
    height: 275px;
    object-fit: cover;
    /* border: 2px solid red; */
    border-top: 3px solid red;
    border-right: 3px solid red;
    /* border-left: 2px solid red; */
    border-radius: 150px 0 0 0 ;
}

.landingWorkoutImgText{
    display: flex;
    width: 487px;
    grid-gap: 50px;
    gap: 50px;
    place-items: center;
    background-color: rgba(24, 23, 26, 1);
    color: white;

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    z-index: 2;
}
.landingWorkoutImage h1{
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    padding-left: 10px;
}
.landingWorkoutImgOverlay{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to top, rgba(24, 23, 26, 1) 0%,  rgba(255, 255, 255, 0) 50%);
    /* background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%); */
    height: 285px;
    width: 100%;
}

.landingWorkoutImage_1{
    position: relative;
}
.landingWorkoutImage_1 img{
    width: 149px;
    height: 346px;
    object-fit: cover;
    /* border: 2px solid red; */
    border-top: 3px solid red;
    /* border-left: 2px solid red; */
    border-radius: 75px 0 0 0 ;
}

.landingWorkoutImage_1 h1{
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;

    font-family: Plus Jakarta Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    z-index: 2;
}
.landingWorkoutImg_1_Overlay{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to top, rgba(24, 23, 26, 1) 0%,  rgba(255, 255, 255, 0) 50%);
    /* background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%); */
    height: 100%;
    width: 100%;
}

.landingHabits{
    height: 650px;
    width: 100%;
    background: linear-gradient(0deg, rgba(10, 10, 10, 1) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.2) 90%, rgba(10, 10, 10, 1) 100%), url(/assets/background.png);
    background-size: cover;
    /* margin-top: -20px; */
    padding-top: 2px;
}
.landingHabitsCards{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    /* flex-wrap: wrap; */
}

.landingHabitsCardsCard{
    width: 400px;
    padding: 5px;
}
.landingHabitsCardsCard li::marker{
    color: #DB2719;
}

.landingHeaderSS{
    display: none;
}

.landingSideBar{
    display: none;
}
.landingSideBar::-webkit-scrollbar{
    width: 0;
}

.landingSideBarOverlay{
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: 0%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
}


@keyframes moveSlideshow {  
    0% { 
      -webkit-transform: translateX(0);  
    }
    20% { 
        -webkit-transform: translateX(0);  
    }
    25% { 
        -webkit-transform: translateX(-100%);  
    }
    45% { 
        -webkit-transform: translateX(-100%);  
    }
    50% { 
        -webkit-transform: translateX(-200%);  
    }
    70% { 
        -webkit-transform: translateX(-200%);  
    }
    75% { 
        -webkit-transform: translateX(-300%);  
    }
    95% { 
        -webkit-transform: translateX(-300%);  
    }
    100% { 
        -webkit-transform: translateX(-400%);  
    }
}


@media screen and (max-width: 1200px){
    .landingImageCaption{
        font-size: 40px;
    }
    .landingImageCaption span{
        font-size: 48px;
    }

    .landingHeadings{
        /* width: 100%; */
        text-align: center;
        margin: 30px 0 20px 0;
    }
    .landingFindWhatMovesYou{
        transform: scale(0.9);
        /* margin-top: -50px; */
    }
    .landingMakeItYourLife{
        transform: scale(0.9);
        margin-top: -25px;
    }
    .landingHabits{
        height: 500px;
    }
    .landingHabitsCards{
        grid-gap: 16px;
        gap: 16px;
    }
    .landingHabitsCardsCard{
        transform: scale(0.8);
        margin: -40px;
    }
}

@media screen and (max-width: 1000px) {
    .landingHeaderRoutes{
        width: 80px;
        font-size: 16px;
    }
    .landingHeader button{
        width: 80px;
        font-size: 16px;
    }
    .landingHabits{
        height: 550px;
        
    }
    .landingHabitsCards::-webkit-scrollbar{
        height: 0px;
    }
    .landingHabitsCards{
        overflow: auto;
        white-space: nowrap;
        padding: 10px;
        margin: 0 10px;
        scroll-snap-type: x mandatory;
        justify-content: flex-start;
    }
    .landingHabitsCardsCard{
        padding: 10px;
        scroll-snap-align: center;
    }
    .landingHeadings.responsive{
        font-size: 36px;
    }
}

@media screen and (max-width: 800px){
    .landingHeader{
        display: none;
    }
    .landingHeaderSS{
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        height: 70px;
        /* background-color: var(--primary-1); */
    }
    .landingHeaderIcon{
        align-self: center;
        justify-self: center;
        fill: var(--primary-1);
    }

    .landingSideBar{
        display: block;
    }
    .landingSideBarOverlay{
        display: block;
    }

    .landingImageSlider{
        height: 500px;
    }
    .landingImageCaption{
        font-size: 32px;
        bottom: 20px;
        left: 30px;
    }
    .landingImageCaption span{
        font-size: 40px;
    }
    .landingImageOverlay_h{
        background: linear-gradient(to top, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%);
    }
}

@media screen and (max-width: 600px){
    .landingImageCaption{
        font-size: 20px;
        bottom: 10px;
        left: 20px;
    }
    .landingImageCaption span{
        font-size: 26px;
        -webkit-text-stroke-width: 1px;
    }

    .landingFindWhatMovesYou{
        transform: scale(0.7);
        margin-top: -125px;
    }
    .landingMakeItYourLife{
        transform: scale(0.7);
        margin-top: -225px;
    }
    .landingHeadings.responsive{
        font-size: 30px;
    }

    .landingHabits{
        margin-top: -80px;
    }
}
.decorativeImageContainer{
    display: grid;
    justify-content: center;
    align-content: flex-end;
    position: relative;
    height: 357px;
    margin-bottom: 40px;
}
.decorativeImageContainer img{
    position: absolute;
    top: 0;
    left: calc(50% - 242px);
    z-index: 1;
    height: 346px;
    width: 484px;
    object-fit: cover;
}
