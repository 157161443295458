.registerContainer{
    height: 100vh;
    width: 100vw;
    display: grid;
    justify-items: center;
    align-content: center;
    background-color: var(--primary-2);
}

.registerHeading{
    place-self: center;
    color: var(--secondary-3);
    font-family: Plus Jakarta Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    letter-spacing: -1.2px;
    margin: 10px;
}

.registerForm{
    display: grid;
    width: max(30vw,250px);
    padding: 20px;
    gap: 20px;
    background-color: var(--primary-1);
    border-radius: 10px;
}


.formInputContainer{
    display: grid;
    gap: 5px;
    color: var(--secondary-2);
    /* Semibold/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}

.formInputField{
    width: 90%;
    height: 45px;
    border-radius: 10px;
    outline: none;
    background-color: var(--primary-2);
    border: none;
    padding: 0 5%;
}

.registerSubmit{
    width: 100%;
    height: 45px;
    background-color: var(--secondary-2);
    border-radius: 10px;
    border: none;
    color: var(--primary-1);

    /* Semibold/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}

.error{
    color: red;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}

.formLink{
    text-align: center;
}