::-webkit-scrollbar {
    width: 3px;
}
  
::-webkit-scrollbar-track {
  background-color: #F1F0F9;
}

::-webkit-scrollbar-thumb {
  background-color: #E2E6EB;
}

:root{
  --primary-1: #FFFFFF;
  --primary-2: #F6F7F9;
  --primary-3: #90A3BF;
  --primary-4: #596780;
  --primary-5: #1A202C;
  --secondary-0: #AEC8FC;
  --secondary-1: #85A8F8;
  --secondary-2: #3563E9;
  --secondary-3: #1A37A7;
  --secondary-4: #0A196F;
  
}

.text{
  font-family: Plus Jakarta Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 60px */
  letter-spacing: -1.2px;
}
.text.sz_12{
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0px;
}
.text.sz_14{
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
}
.text.sz_16{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
}
.text.sz_18{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
}
.text.sz_20{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.text.sz_24{
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.text.sz_32{
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -1px;
}
.text.wt_3{
  font-weight: 300;
}
.text.wt_4{
  font-weight: 400;
}
.text.wt_5{
  font-weight: 500;
}
.text.wt_6{
  font-weight: 600;
}
.text.clr_w{
  color: white;
}
.text.clr_r{
  color: #DB2719;
}
.text.mr_5{
  margin: 5px 0px;
}
.text.aln_c{
  text-align: center;
}

.homeContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.header{
  width: 100vw;
}

.homeContent{
  display: flex;
  /* overflow-y: scroll; */
}

.homeContentGyms{
  width: 100%;
  /* height: 100vh; */
}