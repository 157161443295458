.blogsHeader{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    place-items: center;
    height: 90px;
    /* background: linear-gradient(0deg, var(--primary-2) 0%, var(--primary-1) 50%); */
    background-color: var(--primary-5);
    width: 100vw;
    
}

.blogsHeaderLogo{
    display: flex;
    color: var(--primary-1);
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    place-items: center;
}
.logo{
    height: 70px;
    width: 70px;
    fill: var(--primary-5);
    border-radius: 100px;
    border: 2px solid var(--primary-5);
    background-color: var(--primary-1);
    /* paint-order: stroke;
    stroke: var(--primary-1);
    stroke-width: 15px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    font-weight: 800; */
}
.logoText{
    height: 90px;
    display: grid;
    grid-template-rows: 1fr 18px;
    align-items: flex-end;
}
.logoSlang{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;   
    font-size: 18px;
    margin-left: 20px;
}

.blogsHeaderOptions{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;

    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 1.5px;
    gap: 10px;
}

.blogsHeaderRoutes{
    width: 100px;
    padding: 4px;
    color: var(--primary-1);
}
.blogsHeaderRoutes:hover{
    /* background-color: var(--primary-3); */
    border-bottom: 3px solid var(--primary-1);
}
.blogsHeader button{
    border: none;
    border: 1px solid var(--primary-1);
    background: transparent;
    color: var(--primary-1); 
    width: 100px;
    /* border-radius: 4px; */
    margin: 8px;
    padding: 4px;
    /* transform: skewX(-15deg); */
    font-size: 18px;
    letter-spacing: 1px;
}
.blogsHeader button:hover{
    background-color: var(--primary-1);
    color: black;
    cursor: pointer;
}

.blogsHeaderSS{
    display: none;
}

.blogsSideBar::-webkit-scrollbar{
    width: 0;
}

@media screen and (max-width: 1000px) {
    .blogsHeaderRoutes{
        width: 80px;
        font-size: 16px;
    }
    .blogsHeader button{
        width: 80px;
        font-size: 16px;
    }
    .blogsHeaderLogo{
        font-size: 36px;
    }
    .logo{
        height: 60px;
        width: 60px;
    }
}

@media screen and (max-width: 800px){
    .blogsHeader{
        display: none;
    }
    .blogsHeaderLogo{
        color: var(--primary-1);
    }
    .logo{
        height: 50px;
        width: 50px;
    }
    .logoText{
        height: 70px;
        display: grid;
        grid-template-rows: 1fr 14px;
        align-items: flex-end;
    }
    .logoSlang{
        font-size: 16px;
    }
    .blogsHeaderSS{
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        height: 70px;
        width: 100vw;
        background-color: var(--primary-5);
        place-items: center;
    }
    .blogsHeaderIcon{
        align-self: center;
        justify-self: center;
        fill: var(--primary-1);
    }
}