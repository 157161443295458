.workoutsCardContainer{
    display: grid;
    grid-template-rows: 1.2fr 1fr;
    height: 100%;
    width: 97%;
    cursor: pointer;
    background: var(--primary-1);
    border-radius: 10px;
    overflow: hidden;
    margin: 5px;
}
.workoutsCardContainer:hover{
    box-shadow: 0px 0px 5px 0px var(--primary-4);
}

.workoutsCardImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.workoutsCardDetails{
    padding: 10px;
    display: grid;
    /* align-items: center; */
    gap: 4px;
}

.workoutsCardDetails > h1{
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    word-spacing: 2px;
}
.workoutsCardDetails > h2{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-4);
    /* letter-spacing: 0.25px; */
    word-spacing: 1px;
}
.workoutsCardDetails > h3{
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-3);
}
