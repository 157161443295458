.policyContainer{
    display: grid;
    background-color: var(--primary-1);
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    place-items: center;
}

.policyTerms{
    background-color: var(--primary-1);
    width: min(90%,800px);
    padding: 20px;
}