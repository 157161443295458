.bmiCalcContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.bmiCalcContent{
    display: flex;
}

.bmiCalcFormContainer{
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    place-items: center;
    margin: 20px;
    background-color: var(--primary-1);
    overflow-y: scroll;
    overflow-x: hidden;
}

.bmiCalcHeading{
    text-align: center;
    margin-top: 10px;  

    color: var(--secondary-3);

    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -0.50px;
}

.bmiInfo{
    margin: 10px;
    max-width: 600px;
    text-align: justify;

    color: var(--primary-5);

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
}
.checkBmiForm{
    width: min(50%,400px);
    display: grid;
    grid-gap: 20px;
    padding: 30px;
    border: 3px solid var(--primary-2);
    border-radius: 10px;
    /* margin-bottom: 20px; */
}
.bmiFormInputDiv{
    display: grid;
}
.bmiFormLabel{
    font-size: 20px;
}
.bmiFormButton{
    height: 35px;
    background-color: var(--secondary-2);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 18px;
}
.bmiFormRadioButton{
    float: right;
    font-size: 15px;
    margin: 2px;
}
.bmiValue{
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--secondary-3);
}

input[type ="radio"]{
    margin: 2px;
    accent-color: var(--secondary-2);
}

.bmiChart{
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: min(350px,90%);
    margin: 25px 0 10px 0;
    /* border-top: 2px solid black; */
    /* border-left: 2px solid black; */
    color: var(--primary-1);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    gap: 1px;
}
.bmiChartValues{
    /* border-bottom: 2px solid black; */
    /* border-right: 2px solid black; */
    padding: 5px;
    gap: 1px;
    margin-top: 1px;
}
.bmiRangeValue{
    /* border-bottom: 2px solid black; */
    /* border-right: 2px solid black; */
    text-align: center;
    padding: 5px;
}