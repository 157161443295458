.gymCardContainer{
    display: grid;
    grid-template-rows: 1fr 1.4fr;
    height: 430px;
    width: 340px;
    cursor: pointer;
    margin: 5px;
    background: var(--primary-1);
    border-radius: 10px;
    overflow: hidden;
}
.gymCardContainer:hover{
    box-shadow: 0px 0px 5px 0px var(--primary-4);
}

.gymCardImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.gymCardDetails{
    background: var(--primary-1);
    padding: 10px 20px 20px 20px;
}

.gymCardTitle{
    color: var(--secondary-2);

    /* Bold/Type@32 */
    font-family: Plus Jakarta Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -0.50px;
}

.gymCardStar{
    height: 16px;
    width: 16px;
    fill: gold;
    margin: 2px;
}

.gymCardContactInfo{
    margin: 20px 0;
    display: grid;
    gap: 10px
}
.gymInfo{
    display: flex;

    color: var(--primary-4);
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
}
.gymCardInfoIcon{
    height: 18px;
    width: 18px;
    fill: var(--primary-5);
    place-self: center;
    margin-right: 10px;
}
.gymCardContactInfoText{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
}

.gymCardFeeInfo{
    display: grid;
    width: 90%;
}

.gymCardFeeInfoRow{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* justify-content: center; */
    gap: 30px;

    color: var(--primary-4);
    /* Regular/Type@20 */
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
}

.gymCardFeeInfoSpan{
    color: var(--primary-5);
    float: right;
    margin-left: 5px;
}

@media screen and (max-width: 400px) {
    .gymCardContainer{
        transform: scale(0.95);
        margin: 0;
    }
}