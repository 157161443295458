.landingContainer{
    /* height: 100vh;
    width: 100vw;
    background-image: linear-gradient(0deg, rgb(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255,255,255,1) 100%), 
                        url("/assets/landing_2.jpg");
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat; */
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #18171A; /*rgba(24, 23, 26, 1)*/
}
.landingContainer::-webkit-scrollbar{
    width: 0px;
}

.landingHeadings{
    /* width: 100%; */
    text-align: center;
    margin: 50px 0 20px 0;

    color: white;

    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.landingHeadings.small{
    font-size: 16px;
    margin: 0 0 20px 0;
}

.landingHeader{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    place-items: center;
    margin: 5px 0;
    height: 80px;
    /* padding: 20px 0; */
    /* background-color: var(--primary-4); */
}
.landingHeaderOptions{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;

    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.landingHeaderRoutes{
    width: 100px;
    padding: 4px;
    color: white;
}
.landingHeaderRoutes:hover{
    /* background-color: var(--primary-3); */
    border-bottom: 3px solid #DB2719;
}
.landingHeader button{
    border: none;
    border: 2px solid var(--primary-5);
    border-right: 3px solid #DB2719;
    border-left: 3px solid #DB2719;
    background: transparent;
    color: white; 
    width: 100px;
    /* border-radius: 4px; */
    margin: 8px;
    padding: 4px;
    /* transform: skewX(-15deg); */
    font-size: 18px;
}
.landingHeader button:hover{
    background-color: #DB2719;
    color: white;
    cursor: pointer;
}

.landingImageSlider{
    height: 600px;
    width: 100vw;
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
}
.slide{
    height: 100%;
    width: 100vw;
    scroll-snap-align: start;
    place-items: center;
    position: relative;
    transform: transale3d(0,0,0); 
    animation: moveSlideshow 20s linear infinite;
}
.landingImageContainer{
    width: 100vw;
    height: 100%;
    position: relative;
}
.landingImageCaption{
    position: absolute;
    bottom: 30px;
    left: 40px;
    z-index: 2;
    color: white;
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.landingImageCaption span{
    color: transparent;
    -webkit-text-stroke-width: 2.5px;
    -webkit-text-stroke-color: red;
    letter-spacing: 3px;
    font-size: 48px;
}
.landingImageSlider img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.landingImageOverlay_h{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 75%);
    /* background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%); */
    height: 100%;
    width: 100%;
}
.landingImageSlider::-webkit-scrollbar{
    height: 0px;
}

.landingFindWhatMovesYou{
    width: 100vw;
}
.landingWorkoutTypes{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}
.landingWorkoutImage{
    position: relative;
}
.landingWorkoutImage img{
    width: 487px;
    height: 275px;
    object-fit: cover;
    /* border: 2px solid red; */
    border-top: 3px solid red;
    border-right: 3px solid red;
    /* border-left: 2px solid red; */
    border-radius: 150px 0 0 0 ;
}

.landingWorkoutImgText{
    display: flex;
    width: 487px;
    gap: 50px;
    place-items: center;
    background-color: rgba(24, 23, 26, 1);
    color: white;

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    z-index: 2;
}
.landingWorkoutImage h1{
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    padding-left: 10px;
}
.landingWorkoutImgOverlay{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to top, rgba(24, 23, 26, 1) 0%,  rgba(255, 255, 255, 0) 50%);
    /* background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%); */
    height: 285px;
    width: 100%;
}

.landingWorkoutImage_1{
    position: relative;
}
.landingWorkoutImage_1 img{
    width: 149px;
    height: 346px;
    object-fit: cover;
    /* border: 2px solid red; */
    border-top: 3px solid red;
    /* border-left: 2px solid red; */
    border-radius: 75px 0 0 0 ;
}

.landingWorkoutImage_1 h1{
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;

    font-family: Plus Jakarta Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    z-index: 2;
}
.landingWorkoutImg_1_Overlay{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to top, rgba(24, 23, 26, 1) 0%,  rgba(255, 255, 255, 0) 50%);
    /* background: linear-gradient(to right, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%); */
    height: 100%;
    width: 100%;
}

.landingHabits{
    height: 650px;
    width: 100%;
    background: linear-gradient(0deg, rgba(10, 10, 10, 1) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.2) 90%, rgba(10, 10, 10, 1) 100%), url(/assets/background.png);
    background-size: cover;
    /* margin-top: -20px; */
    padding-top: 2px;
}
.landingHabitsCards{
    display: flex;
    gap: 20px;
    justify-content: center;
    /* flex-wrap: wrap; */
}

.landingHabitsCardsCard{
    width: 400px;
    padding: 5px;
}
.landingHabitsCardsCard li::marker{
    color: #DB2719;
}

.landingHeaderSS{
    display: none;
}

.landingSideBar{
    display: none;
}
.landingSideBar::-webkit-scrollbar{
    width: 0;
}

.landingSideBarOverlay{
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: 0%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
}


@keyframes moveSlideshow {  
    0% { 
      -webkit-transform: translateX(0);  
    }
    20% { 
        -webkit-transform: translateX(0);  
    }
    25% { 
        -webkit-transform: translateX(-100%);  
    }
    45% { 
        -webkit-transform: translateX(-100%);  
    }
    50% { 
        -webkit-transform: translateX(-200%);  
    }
    70% { 
        -webkit-transform: translateX(-200%);  
    }
    75% { 
        -webkit-transform: translateX(-300%);  
    }
    95% { 
        -webkit-transform: translateX(-300%);  
    }
    100% { 
        -webkit-transform: translateX(-400%);  
    }
}


@media screen and (max-width: 1200px){
    .landingImageCaption{
        font-size: 40px;
    }
    .landingImageCaption span{
        font-size: 48px;
    }

    .landingHeadings{
        /* width: 100%; */
        text-align: center;
        margin: 30px 0 20px 0;
    }
    .landingFindWhatMovesYou{
        transform: scale(0.9);
        /* margin-top: -50px; */
    }
    .landingMakeItYourLife{
        transform: scale(0.9);
        margin-top: -25px;
    }
    .landingHabits{
        height: 500px;
    }
    .landingHabitsCards{
        gap: 16px;
    }
    .landingHabitsCardsCard{
        transform: scale(0.8);
        margin: -40px;
    }
}

@media screen and (max-width: 1000px) {
    .landingHeaderRoutes{
        width: 80px;
        font-size: 16px;
    }
    .landingHeader button{
        width: 80px;
        font-size: 16px;
    }
    .landingHabits{
        height: 550px;
        
    }
    .landingHabitsCards::-webkit-scrollbar{
        height: 0px;
    }
    .landingHabitsCards{
        overflow: auto;
        white-space: nowrap;
        padding: 10px;
        margin: 0 10px;
        scroll-snap-type: x mandatory;
        justify-content: flex-start;
    }
    .landingHabitsCardsCard{
        padding: 10px;
        scroll-snap-align: center;
    }
    .landingHeadings.responsive{
        font-size: 36px;
    }
}

@media screen and (max-width: 800px){
    .landingHeader{
        display: none;
    }
    .landingHeaderSS{
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        height: 70px;
        /* background-color: var(--primary-1); */
    }
    .landingHeaderIcon{
        align-self: center;
        justify-self: center;
        fill: var(--primary-1);
    }

    .landingSideBar{
        display: block;
    }
    .landingSideBarOverlay{
        display: block;
    }

    .landingImageSlider{
        height: 500px;
    }
    .landingImageCaption{
        font-size: 32px;
        bottom: 20px;
        left: 30px;
    }
    .landingImageCaption span{
        font-size: 40px;
    }
    .landingImageOverlay_h{
        background: linear-gradient(to top, rgb(0, 0, 0, 1) 0%,  rgba(255, 255, 255, 0) 50%);
    }
}

@media screen and (max-width: 600px){
    .landingImageCaption{
        font-size: 20px;
        bottom: 10px;
        left: 20px;
    }
    .landingImageCaption span{
        font-size: 26px;
        -webkit-text-stroke-width: 1px;
    }

    .landingFindWhatMovesYou{
        transform: scale(0.7);
        margin-top: -125px;
    }
    .landingMakeItYourLife{
        transform: scale(0.7);
        margin-top: -225px;
    }
    .landingHeadings.responsive{
        font-size: 30px;
    }

    .landingHabits{
        margin-top: -80px;
    }
}