.gymsContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill, 340px);
    justify-content: space-evenly;
    margin-top: 5px;
    gap: 5px;
    height: calc(100vh - 95px);
    overflow-y: scroll;
}

@media screen and (max-width: 400px) {
    .gymsContainer{
        gap: 0;
    }
}