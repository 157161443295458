.headerContainer{
    display: grid;
    grid-template-columns: 300px 1fr 1fr;
    height: 70px;
    background: var(--primary-1);
    transition: 200ms ease-in-out;
}

.headerLogo{
    place-self: center;
    color: var(--secondary-2);
    letter-spacing: -1.2px;

    display: flex;
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    place-items: center;
}
.logoH{
    height: 50px;
    width: 50px;
    fill: var(--secondary-2);
    border-radius: 100px;
    border: 2px solid var(--secondary-2);
    background-color: var(--primary-1);
}
.logoH.red{
    fill: #DB2719;
    border-radius: 100px;
    border: 2px solid #DB2719;
    background-color: var(--primary-5);
}
.logoHText{
    height: 70px;
    display: grid;
    grid-template-rows: 1fr 12px;
    align-items: flex-end;
}
.logoHSlang{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;   
    font-size: 16px;
    margin-left: 20px;
}

.headerSearchBar{
    position: relative;
    place-self: center;
    display: grid;
    grid-template-columns: 1fr 40px;
    width: 95%;
    height: 40px;
    border-radius: 70px;
    border: 1px solid rgba(195, 212, 233, 0.40);
    background-color: var(--primary-2);
    padding: 0 10px 0 10px;
    
}
.headerSearchBar:focus-within{
    border: 2px solid orange;
}
.headerSearchIcon{
    align-self: center;
    justify-self: center;
    /* margin-left: 13px; */
    color: var(--primary-2);
    height: 20px;
    width: 20px;
    opacity: 0.6;
}
.headerSearchInputBox{
    width: 95%;
    border: none;
    text-align: justify;

    /* Medium/Type@14 */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.5px;
    word-spacing: 1.5px;
    background-color: var(--primary-2);
    place-self: center;
}
.headerSearchInputBox:focus{
    border: none;
    outline: none;
}
.headerSearchResults{
    position: absolute;
    top: 50px;
    left: 0px;
    width: 95%;
    z-index: 3;
    background-color: var(--primary-1);
    box-shadow: 0px 0px 5px 0px var(--primary-4);
    border-radius: 10px;
    padding: 10px;

    text-align: left;
    vertical-align: middle;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.5px;
    word-spacing: 1.5px;

    display: grid;
}
.headerSearchResultLinks{
    text-decoration: none;
    color: var(--primary-5);
    width: 95%;
    height: 40px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 2px solid var(--primary-2);
    place-self: center;
    cursor: pointer;
    padding: 0 10px;
}
.headerSearchResultLinks:hover{
    background-color: var(--primary-2);
}
.headerSearchResultLinks:last-child{
    border: none;
}

.headerMenu{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-right: 20px;
}
.headerMenuButtons{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    border: 1px solid rgba(195, 212, 233, 0.40);
}
.headerMenuButtons:hover{
    background: var(--secondary-2);
    cursor: pointer;
}
.headerMenuIcon{
    height: 20px;
    width: 20px;
}
.headerMenuButtons:hover > .headerMenuIcon{
    fill: white;
}

.searchIcon{
    display: none;
}

.headerFilterBar{
    height: 45px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
}
.headerFilterBar::-webkit-scrollbar{
    display: none;
}

.filterBtn{
    width: 80%;
    height: 27px;
    background: #E2E6EB;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px;
}

.headerContainerSS{
    display: none;
}

.userDropDown{
    position: absolute;
    top: 70px;
    right: 35px;
    width: 200px;
    z-index: 3;
    background-color: var(--primary-1);
    box-shadow: 0px 0px 5px 0px var(--primary-4);
    border-radius: 10px;
    padding: 10px;

    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.userInfo{
    font-size: 20px;
    margin-bottom: 20px;
}
.dropDownItems{
    border-radius: 10px;
    margin-top: 4px;
    padding: 5px;
    display: grid;
    gap: 5px;
    place-items: center;
}
.dropDownItemOption{
    height: 30px;
    width: 90%;
    border-radius: 10px;
    padding-top: 8px;
    text-align: center;
    text-decoration: none;
    border: 1px solid var(--primary-2);
}
.dropDownItemOption:hover{
    background-color: var(--secondary-2);
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .headerContainer{
        grid-template-columns: 250px 1.5fr 1fr;
    }
    .headerLogo{
        font-size: 36px;
    }
    
}

@media screen and (max-width: 800px) {
    .headerContainer{
        display: none;
    }
    .headerContainerSS{
        display: grid;
        grid-template-columns: 60px 1fr 60px;
        height: 70px;
        background-color: var(--primary-1);
    }
    .headerIcon{
        align-self: center;
        justify-self: center;
        fill: var(--primary-5);
    }
    .headerIcon:hover{
        background-color: var(--primary-2);
        padding: 10px;
        border-radius: 50px;
    }
    .headerLogo{
        
    }
    .headerLogo.hide{
        display: none;
    }

    .searchIcon{
        display: block;
        position: absolute;
        z-index: 0;
        right: 10px;
        top: 10px;
    }
    

    .headerToggleSideBar{
        top: 5px;
      }
}