.blogsContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.blogsContent{
    display: flex;
}

.blogsFiltersList{
    display: grid;
    width: calc(100% - 30px);
    padding: 10px;
}

.blogsFiltersScroll{
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 0 20px 0 20px; */
    /* justify-content: center; */
    overflow: hidden;
}
.blogsFilters{
    width: fit-content;
    display: flex;
    gap: 10px;
    padding-left: 20px;
    overflow: scroll;
}
.blogsFilters::-webkit-scrollbar{
    height: 0;
    width: 0;
}
.blogsFilterButton{
    width: fit-content;
    border: 1px solid var(--primary-5);
    border-radius: 5px;
    /* margin: 10px; */
    padding: 2px 10px;
}
.blogsFilterButton:hover{
    cursor: pointer;
    background-color: var(--secondary-2);
    color: white;
    border: none;
}
.blogsFilterButton.selected{
    background-color: var(--secondary-2);
    color: white;
    border: none;
}

.blogsList{
    width: 100%;
    height: calc(100vh - 120px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(315px,1fr));
    grid-template-rows: repeat(auto-fit,390px);
    gap: 10px;
    /* padding: 10px; */
    margin: 10px;
    overflow: auto;
}
.blogsList::-webkit-scrollbar{
    height: 0;
}

.blogsLinks{
    text-decoration: none;
    color: black;
}