.toolsContainer{
    background: var(--primary-2);
    display: grid;
    grid-template-rows: 70px 1fr;
    height: 100vh;
    overflow: hidden;
}

.toolsContent{
    display: flex;
}

.toolsCalculators{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-template-rows: repeat(auto-fill,200px);
    justify-content: space-evenly;
    gap: 10px;
    padding: 10px;
}

.toolsCalc{
    height: 200px;
    width: 200px;
    background-color: var(--primary-1);
    border-radius: 10px;
    display: grid;
    place-items: center;

    color: var(--secondary-2);

    /* Bold/Type@32 */
    font-family: Plus Jakarta Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -0.50px;
}