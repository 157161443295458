.blogsFooterContainer{
    width: 100%;
    background-color: var(--primary-5);
    color: white;
    display: grid;
    place-items: center;
    gap: 10px;
}

.blogsFooterUpper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 200px;
    gap: min(100px, 10vw);
    font-family: Plus Jakarta Sans;
    padding: 50px 10px 0 10px;
}

.blogsFooterContactExplore{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 17px;
    justify-self: center;
}

.blogsFooterLinks{
    text-decoration: none;
    color: var(--primary-3);
}
.blogsFooterLinks:hover{
    text-decoration: underline;
}

.blogsFooterLower{
    background-color: var(--primary-4);
    width: 100%;
    height: 50px;
    display: flex;
    place-items: center;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 500px){
    .blogsFooterContactExplore{
        font-size: 14px;
    }
}
