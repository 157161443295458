.textInput{
    position: relative;
}
.textInput input{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border: 2px solid var(--primary-4);
    outline: none;
    background: transparent;

    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background-color: var(--primary-1); */
    padding: 0 5%;

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 1px;
    word-spacing: 2px;
}

.textInput span{
    position: absolute;
    top: 22%;
    left: 15px;
    transition: 0.3s;
    padding: 0 2px;
    background-color: var(--primary-1);
    color: var(--primary-4);

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 1px;
    word-spacing: 2px;
}

.textInput input:focus{
    /* border: 2px solid var(--secondary-3); */
}

.textInput input:focus ~ span,
.textInput input:valid ~ span {
  transform: translateX(-10%) translateY(-100%);
  color: var(--primary-5);
  font-size: 14px;
  z-index: 2;
}