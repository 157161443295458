.sideBarContainer{
    width: 300px;
    height: 100%;
    background: var(--primary-1);
    transition: all 0.3s;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--primary-2);
    padding: 16px;
}


.sideBarContainer.close{
    width: 45px;
}

.sideBarToggleArrow{
    border: 1px solid var(--primary-4);
    border-radius: 50px;
    height: 20px;
    width: 20px;
    float: right;
    margin-bottom: 10px;
    fill: var(--primary-4);
}
.sideBarToggleArrow:hover{
    cursor: pointer;
    border-color: var(--secondary-2);
    background-color: var(--secondary-2);
    fill: var(--primary-1);
}
.sideBarToggleArrow.right{
    margin: 0 10px 20px 0;
}

.sideBarHeading{
    color: rgba(148, 167, 203, 0.40);
    text-align: justify;

    /* Semibold/Type@12 */
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.24px;
    margin: 20px 0 20px 10px;
}

.sideBarButton{
    display: flex;
    width: 100%;
    height: 50px;
    margin: 5px 0;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}
.sideBarButton:hover{
    background: var(--secondary-2);
    cursor: pointer;
}
.sideBarButtonIcon{
    margin: 10px;
    height: 20px;
    width: 20px;
    display: grid;
    place-items: center;
    fill: var(--primary-4);
}
.sideBarButton:hover > .sideBarButtonIcon{  
    fill: white;
}
.sideBarButtonText{
    color: var(--primary-4);
    text-align: justify;

    /* Medium/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
    margin: 10px;
}
.sideBarButton:hover > .sideBarButtonText{
    color: white;
}
.sideBarButtonText.close{
    display: none;
}

.sideBarTheme{
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}
.themeCircle{
    height: 12px;
    width: 12px;
    border-radius: 10px;
    border: 1px solid var(--secondary-2);
    margin-left: 10px;
    background: content-box;
    padding: 1px;
}
.themeCircle:hover{
    cursor: pointer;
}

.overlay{
    background-color: black;
    opacity: 0.7;
    z-index: 1;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: none;
}

.linkTo{
    text-decoration: none;
}

.sideBarUser{
    display: none;
}

.sideBarAuth{
    display: none;
}

.sideBarClose{
    display: none;
}


@media screen and (max-width: 800px){
    .sideBarContainer{
        position: absolute;
        z-index: 2;
        top: 0%;
        width: 250px;
        height: calc(100vh - 32px);
        overflow-y: scroll;
        border: none;
    }
    .sideBarContainer.close{
        width: 0px;
        padding: 0;
    }
    .overlay{
        display: block;
        height: 100vh;
    }

    .sideBarToggle{
        display: none;
    }

    .sideBarClose{
        display: block;
        height: 20px;
        width: 20px;
        text-align: center;
        position: absolute;
        left: 250px;
        top: 10px;
        z-index: 1;
        font-weight: 700;
        background-color: var(--primary-3);
        border-radius: 10px;
    }

    .sideBarUser{
        display: flex;
        place-items: center;
    }
    .sideBarUser > span{
        color: var(--primary-5);
        text-align: justify;

        /* Medium/Type@16 */
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.32px;
        margin: 10px;
    }
    .sideBarUserIcon{
        height: 28px;
        width: 28px;
        background-color: var(--secondary-2);
        border-radius: 30px;
        fill: var(--primary-1);
        padding: 10px;
        margin: 10px;
    }
    .sideBarSettings{
        display: grid;
    }

    .sideBarAuth{
        display: block;
        margin-top: 20px;
    }
    .sideBarAuthButton{
        border-radius: 10px;
        margin-top: 4px;
        padding: 5px;
        display: grid;
        gap: 5px;
        place-items: center;
    }
    .sideBarAuthButtonValue{
        height: 30px;
        width: 90%;
        border-radius: 10px;
        padding-top: 8px;
        text-align: center;
        text-decoration: none;
        border: 1px solid var(--primary-3);
    }
    .sideBarAuthButtonValue:hover{
        background-color: var(--secondary-2);
        color: white;
        cursor: pointer;
    }

}

.dark{
    color: white;
    fill: white;
}
.darkBack{
    background-color: #18171A;
}
.dark > span{
    color: white;
}