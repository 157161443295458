.blogContainer{
    display: grid;
    place-items: center;
    background-color: var(--primary-1);
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.blogParas{
    background-color: var(--primary-1);
    width: min(90%,800px);
    padding: 50px 20px 20px 20px;
}

.blogsImgContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
}
.blogsImg{
    width: 100%;
    height: min(400px,60vw);
    object-fit: cover;
    overflow: hidden;
}
.blogsImg.small{
    width: 90%;
    height: min(250px, 50vw);
}

.blogsAuthor{
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
}
hr{
    border-top: 2px solid var(--primary-5);
}
.blogsAuthorText{
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.blogsAuthorText.light{
    opacity: 0.7;
}
.blogsAuthorImg{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    object-fit: cover;
    overflow: hidden;
}

.blogParas > h1{
    font-family: Roboto;
    font-style: normal;
    font-size: 32px;
    line-height: 150%; /* 60px */
    margin-bottom: 50px;
    letter-spacing: 0.04em;
    word-spacing: 0.15em;
    font-weight: 600;
}
.blogParas > h2{
    font-family: Roboto;
    font-style: normal;
    font-size: 25px;
    line-height: 150%;
    margin: 50px 0 16px 0;
    font-weight: 600;
    letter-spacing: 0.05em;
    word-spacing: 0.25em;
}
.blogParas > h3{
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
    word-spacing: 0.25em;
}
.blogParas > p{
    font-family: Roboto;
    font-style: normal;
    color: var(--primary-5);
    font-size: 17px;
    line-height: 150%;
    word-spacing: 0.5em;
    letter-spacing: 0.05em;
    font-weight: 400;
    margin-bottom: 36px;
}
.blogParas > ol,li,ul{
    margin: 12px 0;
}

.blogsQuotes{
    text-align: center;
    font-style: italic;
    font-weight: bold;
    border-width:  3px;
    border-style: double;
    padding: 10px 30px;
}

.blogsSideBar{
    display: none;
    position: absolute;
    left: 0%;
    top: 0%;
}
.blogsSideBarOverlay{
    position: absolute;
    display: none;
    width: 100%;
    height: 100vh;
    top: 0%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
}

@media screen and (max-width: 800px){
    .blogsSideBar{
        display: block;
    }
    .blogsSideBarOverlay{
        display: block;
    }
}
