.blogsCardContainer{
    display: grid;
    grid-template-rows: 1fr 1.5fr;
    height: 380px;
    width: 97%;
    cursor: pointer;
    background: var(--primary-1);
    border-radius: 10px;
    overflow: hidden;
}
.blogsCardContainer:hover{
    box-shadow: 0px 0px 5px 0px var(--primary-4);
}

.blogsCardImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.blogsCardDetails{
    padding: 10px;
    display: grid;
    /* align-items: center; */
    gap: 4px;
}

.blogsCardDetails > h1{
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    word-spacing: 2px;
}
.blogsCardDetails > h2{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-4);
    /* letter-spacing: 0.25px; */
    word-spacing: 1px;
}
.blogsCardDetails > h3{
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-3);
}

.blogsCardTag{
    width: fit-content;
    padding: 3px 10px;
    text-align: center;
    text-justify: center;
    border-radius: 15px;
    height: 18px;
}
.blogsCardTag.red{
    background: linear-gradient(0deg, #FFA37A 0%, #DB2719 30%);
}
.blogsCardTag.blue{
    background: linear-gradient(0deg, #98D3FF 0%, #2A60B7 30%);
}
.blogsCardTag.yellow{
    background: linear-gradient(0deg,#FFE488 0%,#DBA32A 30%);
}
.blogsCardTag.green{
    background: linear-gradient(0deg, #D3F178 0%, #7FB519 30%);
}
