.genderSelect{
    display: grid;
    grid-template-columns: 100px 100px;
    gap: 10px;
    margin-left: 50px;
}
.genderSelectBtn{
    border: 2px solid var(--primary-3);
    width: 100px;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: var(--primary-5);
}
.genderSelectBtn.selected{
    background-color: var(--secondary-2);
    color: white;
    border-color: var(--secondary-3);
}

select {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    outline: none;
    background-color: var(--primary-2);
    border: none;
    padding: 0 3%;
    color: var(--primary-5);
    cursor: pointer;
}
select:focus{
    outline: none;
    border: 2px solid var(--secondary-2);
}

.error{
    background-color: #DB2719;
    border-radius: 3px;
}

.bmrChart{
    display: grid;
    min-width: min(350px,90%);
    margin: 25px 0 10px 0;
    color: var(--primary-5);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    gap: 1px;
}
.bmrChartRow{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1px;
}
.bmrChartRow > span{
    padding: 5px;
}
.bmrChartRow:nth-child(odd) > span{
    background-color: var(--secondary-1);
}
.bmrChartRow:nth-child(even) > span{
    background-color: var(--secondary-0);
}
.bmrChartRow > span:nth-child(even){
    text-align: center;
}

.toolsBack{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 20px 0 0;
    font-size: 16px;
}
.toolsBackIcon{
    height: 18px;
    width: 18px;
    border: 2px solid var(--secondary-2);
    border-radius: 20px;
    fill: var(--secondary-2);
    margin: 0 5px;
}
.calcLink{
    display: flex;
    text-decoration: none;
    color: var(--secondary-3);
}
