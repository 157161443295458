.decorativeImageContainer{
    display: grid;
    justify-content: center;
    align-content: flex-end;
    position: relative;
    height: 357px;
    margin-bottom: 40px;
}
.decorativeImageContainer img{
    position: absolute;
    top: 0;
    left: calc(50% - 242px);
    z-index: 1;
    height: 346px;
    width: 484px;
    object-fit: cover;
}