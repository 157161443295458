.loginContainer{
    height: 100vh;
    width: 100vw;
    display: grid;
    justify-items: center;
    align-content: center;
    background-color: var(--primary-2);
}

.loginHeading{
    place-self: center;
    color: var(--secondary-3);
    font-family: Plus Jakarta Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    letter-spacing: -1.2px;
    margin: 10px;
}

.loginForm{
    display: grid;
    width: max(30vw,250px);
    padding: 20px;
    gap: 20px;
    background-color: var(--primary-1);
    border-radius: 10px;
}

.loginSubmit{
    width: 100%;
    height: 45px;
    background-color: var(--secondary-2);
    border-radius: 10px;
    border: none;
    color: var(--primary-1);

    /* Semibold/Type@16 */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
}